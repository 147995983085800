<template>
  <div>
    <UpdateRegister />
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import UpdateRegister from '@/views/perfil/Forms/UpdateRegister.vue'
export default {
  components: {
    BCard,
    BCardText,
    UpdateRegister,
  },
}
</script>