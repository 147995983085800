<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9"
    nav-wrapper-class="col-12 col-md-3"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
        />
        <span class="font-weight-bold">Información Usuario</span>
      </template>

      <account-setting-general/>
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
        />
        <span class="font-weight-bold">Cambio de Clave</span>
      </template>
      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword
  },
  data() {
    return {
      options: {},
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
