<template>
  <div>
    <form-wizard
      color="#0066cc"
      title="Actualización del Usuario"
      subtitle="Formulario para la actualización de datos del usuario"
      shape="square"
      finish-button-text="Enviar"
      back-button-text="Anterior"
      next-button-text="Siguiente"
      class="steps-transparent mb-3"
      @on-complete="UpdateClient"
    >
      <!-- información personal -->
      <tab-content :title="titleTabInformation">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Tipo de cuenta</h5>
            <small> Seleccione el tipo de cuenta </small>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="" label-for="i-accountType">
              <b-form-select
                id="i-accountType"
                v-model="formEditData.accountType"
                :options="optionsAccountType"
                @change="onAccounTypeInput()"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-2" v-if="formEditData.account_type == '1'">
            <h5 class="mb-0">Información personal</h5>
            <small>Ingrese su información personal</small>
          </b-col>
          <b-col cols="12" class="mb-2" v-else>
            <h5 class="mb-0">Información de la empresa</h5>
            <small class="text-muted"
              >Ingrese la información de la empresa</small
            >
          </b-col>
          <!-- Tipo de identificación -->
          <b-col md="6" v-if="formEditData.accountType == '1'">
            <b-form-group
              label="Tipo de identificación"
              label-for="i-identificationType"
            >
              <b-form-select
                id="i-identificationType"
                v-model="formEditData.identificationType"
                :options="optionsIdentificationType"
                @change="onAccounTypeInput()"
              />
            </b-form-group>
          </b-col>
          <!-- Número de identificación -->
          <b-col md="6">
            <b-form-group
              label="Nit o Número de identificación"
              label-for="i-identificationNumber"
            >
              <b-form-input
                id="i-identificationNumber"
                v-model="formEditData.identificationNumber"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <!-- Razón social -->
          <b-col md="6" v-if="formEditData.accountType == '2'">
            <b-form-group label="Razón social" label-for="i-companyName">
              <b-form-input
                id="i-companyName"
                v-model="formEditData.companyName"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <!-- Primer nombre -->
          <b-col md="6">
            <b-form-group label="Primer nombre" label-for="i-firstName">
              <b-form-input
                id="i-firstName"
                v-model="formEditData.firstName"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <!-- Segundo nombre -->
          <b-col md="6">
            <b-form-group label="Segundo nombre" label-for="i-secondName">
              <b-form-input
                id="i-secondName"
                v-model="formEditData.secondName"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <!-- Primer apellido -->
          <b-col md="6">
            <b-form-group label="Primer apellido" label-for="i-firstLastName">
              <b-form-input
                id="i-firstLastName"
                v-model="formEditData.firstLastName"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <!-- Segundo apellido -->
          <b-col md="6">
            <b-form-group label="Segundo apellido" label-for="i-secondLastName">
              <b-form-input
                id="i-secondLastName"
                v-model="formEditData.secondLastName"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <!-- Fecha de nacimiento -->
          <b-col md="6">
            <b-form-group label="Fecha de nacimiento" label-for="i-birthday">
              <b-form-input
                id="i-birthday"
                v-model="formEditData.birthday"
                type="date"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <!-- Género -->
          <b-col md="6" v-if="formEditData.accountType == '1'">
            <b-form-group label="Género" label-for="i-gender">
              <b-form-select
                id="i-gender"
                v-model="formEditData.gender"
                :options="optionsGender"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- Ubicación y contacto -->
      <tab-content title="Ubicación y contacto">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Ubicación y contacto</h5>
            <small class="text-muted"
              >Ingrese sus datos de ubcación y contacto</small
            >
          </b-col>
          <!-- País -->
          <b-col md="6">
            <b-form-group label="País" label-for="i-country">
              <b-form-select
                id="i-country"
                v-model="formEditData.country"
                :options="optionsCountry"
                disabled
                @change="getDepartmentsFromService()"
              />
            </b-form-group>
          </b-col>
          <!-- Departamento/Estado/Provincia -->
          <b-col md="6">
            <b-form-group
              label="Departamento/Estado/Provincia"
              label-for="i-department"
            >
              <b-form-select
                id="i-department"
                v-model="formEditData.department"
                :options="optionsDepartment"
                @change="getCitiesFromService()"
              />
            </b-form-group>
          </b-col>
          <!-- Ciudad -->
          <b-col md="6">
            <b-form-group label="Ciudad" label-for="i-city">
              <b-form-select
                id="i-city"
                v-model="formEditData.city"
                :options="optionsCity"
                @change="getCitiesFromService()"
              />
            </b-form-group>
          </b-col>
          <!-- Barrio/Colonia -->
          <b-col md="6">
            <b-form-group label-for="i-neighborhood" label="Barrio/Colonia">
              <b-form-input
                id="i-neighborhood"
                placeholder=""
                v-model="formEditData.neighborhood"
              />
            </b-form-group>
          </b-col>
          <!-- Código postal -->
          <b-col md="5">
            <b-form-group label-for="i-postcode" label="Código postal">
              <b-form-input
                id="i-postcode"
                placeholder=""
                v-model="formEditData.postcode"
              />
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <br>
            <b-button
              variant="outline-warning"
              size="sm"
              @click="showModalPostCode = true"
              >
                <b-icon icon="search" aria-hidden="true"></b-icon>
              </b-button
            >
          </b-col>
          <b-modal
            v-model="showModalPostCode"
            ok-only
            ok-title="Buscar"
            id="modalSearchPostCode"
            title="Buscar código postal"
            @ok="getPostCodeInfo()"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label-for="i-nameStore"
                  label="Código postal"
                >
                  <b-form-input
                    id="i-nameStore"
                    type="text"
                    placeholder=""
                    v-model="postCodeSearch"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-modal>

          <!-- Dirección -->
          <b-col md="6">
            <b-form-group label-for="i-address" label="Dirección">
              <b-form-input
                id="i-address"
                placeholder=""
                v-model="formEditData.address"
              />
            </b-form-group>
          </b-col>
          <!-- Teléfono fijo -->
          <b-col md="6">
            <b-form-group label-for="i-homePhone" label="Teléfono fijo">
              <b-form-input
                id="i-homePhone"
                placeholder=""
                v-model="formEditData.homePhone"
              />
            </b-form-group>
          </b-col>
          <!-- Teléfono celular -->
          <b-col md="6">
            <b-form-group label-for="i-cellPhone" label="Teléfono celular">
              <b-form-input
                id="i-cellPhone"
                placeholder=""
                v-model="formEditData.cellphone"
              />
            </b-form-group>
          </b-col>
          <!-- Razón social -->
          <b-col md="6" v-if="formEditData.accountType == '2'">
            <b-form-group label="Página web" label-for="i-website">
              <b-form-input
                id="i-website"
                v-model="formEditData.website"
                placeholder=""
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
      <!-- Datos de la cuenta -->
      <tab-content title="Datos de la cuenta">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Datos de la cuenta</h5>
            <small class="text-muted">Ingrese los datos de la cuenta</small>
          </b-col>
          <!-- Email -->
          <b-col md="6">
            <b-form-group label-for="i-email" label="Email">
              <b-form-input
                id="i-email"
                placeholder=""
                v-model="formEditData.email"
              />
            </b-form-group>
          </b-col>
          <!-- Email alterno-->
          <!-- <b-col md="6">
            <b-form-group label-for="i-secondEmail" label="Email alterno">
              <b-form-input
                id="i-secondEmail"
                placeholder=""
                v-model="formEditData.emailSecondary"
              />
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="12">
            <b-form-checkbox
              value="1"
              class="custom-control-primary"
              v-model="formEditData.emailNotification"
            >
              Acepto recibir notificaciones por Email
            </b-form-checkbox>
          </b-col>
          <b-col md="12">
            <b-form-checkbox
              value="1"
              class="custom-control-primary"
              v-model="formEditData.smsNotification"
            >
              Acepto recibir notificaciones por SMS
            </b-form-checkbox>
          </b-col>
          <b-col md="12">
            <b-form-checkbox
              value="1"
              class="custom-control-primary"
              v-model="formEditData.conditions"
            >
              Acepto los términos y condiciones
            </b-form-checkbox>
          </b-col> -->
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BIcon
} from "bootstrap-vue";

import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormCheckbox,
    BFormSelect,
    BIcon,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      showModalPostCode: false,
      postCodeSearch : "",
      sessionData: {},
      selected: false,
      selectedContry: "select_value",
      selectedLanguage: "nothing_selected",
      titleTabInformation: "Información Personal",
      optionsAccountType: [
        { value: "", text: "Seleccione una opción" },
        { value: "1", text: "Persona" },
        { value: "2", text: "Empresa" },
      ],
      optionsIdentificationType: [],
      optionsGender: [
        { value: "", text: "Seleccione una opción" },
        { value: "1", text: "Masculino" },
        { value: "2", text: "Femenino" }
      ],
      optionsCountry: [],
      optionsDepartment: [],
      optionsCity: [],
      formEditData: {
        accountType: "",
        identificationType: "",
        identificationNumber: "",
        firstName: "",
        secondName: "",
        firstLastName: "",
        secondLastName: "",
        birthday: "",
        gender: "",
        email: "",
        emailConfirmation: "",
        smsNotification: "",
        emailSecondary: "",
        emailSecondaryConfirmation: "",
        password: "",
        passwordConfirmation: "",
        country: "",
        department: "",
        city: "",
        neighborhood: "",
        postcode: "",
        postcodeId : "",
        cellphone: "",
        address: "",
        homePhone: "",
        officeId: "",
        lockerId: "",
        emailNotification: "",
        conditions: "",
        companyName: "",
      },
    };
  },
  computed: {
    // sessionData : this.$store.getters["clients/getDataUser"]
  },
  created() {
    // let sessionData = this.$store.getters["clients/getDataUser"]
    this.getUserData();
    this.getIdentificationsTypeFromService();
    this.getCountriesFromService();
    // this.getDepartmentsFromService();
    // this.getCitiesFromService();
  },
  methods: {
    getUserData() {
      this.sessionData = this.$store.state.clients.infoUser;
      // this.$store.getters["clients/getDataUser"]
      console.log(this.sessionData);
      this.getDepartmentsFromService(this.sessionData.pais_codigo,this.sessionData.departamento_id);
      this.getCitiesFromService(this.sessionData.departamento_id, this.sessionData.ciudad_id);
      this.formEditData.accountType = this.sessionData.tipo_cliente_id;
      this.formEditData.identificationType = this.sessionData.tipo_de_identificacion_id;
      this.formEditData.identificationNumber = this.sessionData.numero_de_identificacion;
      this.formEditData.firstName = this.sessionData.primer_nombre;
      this.formEditData.secondName = this.sessionData.segundo_nombre;
      this.formEditData.firstLastName = this.sessionData.primer_apellido;
      this.formEditData.secondLastName = this.sessionData.segundo_apellido;
      this.formEditData.birthday = this.sessionData.fecha_de_nacimiento;
      this.formEditData.gender = this.sessionData.genero_id;
      this.formEditData.email = this.sessionData.email;
      this.formEditData.emailSecondary = this.sessionData.email_alterno;
      // this.formEditData.country = this.sessionData.pais_codigo;
      // this.formEditData.department = this.sessionData.departamento_id;
      // this.formEditData.city = this.sessionData.ciudad_id;
      this.formEditData.neighborhood = this.sessionData.barrio;
      this.formEditData.postcode = this.sessionData.codigo_postal_id;
      this.formEditData.cellphone = this.sessionData.telefono_celular;
      this.formEditData.address = this.sessionData.direccion;
      this.formEditData.homePhone = this.sessionData.telefono_fijo;
      this.formEditData.officeId = this.sessionData.oficina_id;
      this.formEditData.lockerId = this.sessionData.codigo_casillero;
      this.formEditData.emailNotification = this.sessionData.notificacion_email;
      this.formEditData.smsNotification = this.sessionData.notificacion_sms;
      this.formEditData.companyName = this.sessionData.razon_social;
    },
    onAccounTypeInput() {
      if (this.formEditData.accountType.value == "1") {
        this.titleTabInformation = "Información Personal";
      } else {
        this.titleTabInformation = "Información de la Empresa";
      }
    },
    getIdentificationsTypeFromService() {
      this.$http
        .get(`api/tiposdeidentificacion/${process.env.VUE_APP_APPID}`)
        .then((response) => {
          // console.log(response.data.datos);
          this.optionsIdentificationType = [];
          response.data.datos.forEach((idType) => {
            let itemIdType = {
              value: idType.id,
              text: idType.descripcion,
            };
            // console.log(itemIdType);
            this.optionsIdentificationType.push(itemIdType);
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getCountriesFromService() {
      this.$http
        .get(`api/casillero/clientes/getCountries/${process.env.VUE_APP_APPID}`)
        .then((response) => {
          response.data.forEach((country) => {
            let itemCountry = {
              value: country.codigo,
              text: country.nombre,
            };
            this.optionsCountry.push(itemCountry);
          });
          this.formEditData.country = this.sessionData.pais_codigo;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getDepartmentsFromService(countryCode, departmentId) {
      this.$http
        .get(
          `api/casillero/clientes/getDepartaments/${countryCode}/${process.env.VUE_APP_APPID}`
        )
        .then((response) => {
          this.optionsDepartment = [];
          response.data.forEach((department) => {
            let itemDepartment = {
              value: department.id,
              text: department.nombre,
            };
            this.optionsDepartment.push(itemDepartment);
          });
          setTimeout(() => {
            this.formEditData.department = departmentId;
          }, 1000);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getCitiesFromService(departmentId, cityId) {
      this.$http
        .get(
          `api/casillero/clientes/getCities/${departmentId}/${process.env.VUE_APP_APPID}`
        )
        .then((response) => {
          this.optionsCity = [];
          response.data.forEach((city) => {
            let itemCity = {
              value: city.id,
              text: city.nombre,
            };
            this.optionsCity.push(itemCity);
          });
          setTimeout(() => {
            this.formEditData.city = cityId;
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getPostCodeInfo() {
      this.$http.get(`api/casillero/codigospostales/${this.postCodeSearch}/${this.sessionData.pais_codigo}`,{
        headers : {
          Authorization : `Bearer ${this.$session.get('userData').access_token}`
        }
      })
      .then(response => {
        if(response.data.datos){
          this.getDepartmentsFromService(this.sessionData.pais_codigo, response.data.datos.dpto.id);
          this.getCitiesFromService(response.data.datos.dpto.id,response.data.datos.ciudad.id);
          this.formEditData.postcode = this.postCodeSearch;
          this.formEditData.postcodeId = response.data.datos.postal[0].id;
          this.showModalPostCode = false;
        }
      })
      .catch(errors => {
        console.log(errors)
      })
    },
    UpdateClient() {
      this.$http
        .post(
          "api/casillero/miperfil",
          {
            numero_de_identificacion: this.formEditData.identificationNumber,
            primer_nombre: this.formEditData.firstName,
            segundo_nombre: this.formEditData.secondName,
            primer_apellido: this.formEditData.firstLastName,
            segundo_apellido: this.formEditData.secondLastName,
            tipo_de_identificacion_id: this.formEditData.identificationType,
            email: this.formEditData.email,
            email_confirmation: this.formEditData.emailConfirmation,
            genero_id: this.formEditData.gender,
            telefono_celular: this.formEditData.cellphone,
            telefono_fijo: this.formEditData.homePhone,
            direccion: this.formEditData.address,
            departamento_id: this.formEditData.department,
            ciudad_id: this.formEditData.city,
            // codigo_postal_id: this.formEditData.postcodeId,
            // codigo_casillero: this.formEditData.lockerCode,
          },
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.formSubmitted({
            title: "Operación Exitosa",
            icon: "EditIcon",
            variant: "success",
          });
        })
        .catch((errors) => {
          console.log(errors);
          this.formSubmitted({
            title: "Ocurrió un error inesperado",
            icon: "EditIcon",
            variant: "success",
          });
        });
    },
    formSubmitted(dataToast) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: dataToast.title,
          icon: dataToast.icon,
          variant: dataToast.variant,
        },
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
